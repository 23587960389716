.sidebar{
    flex: 0.9;
    height: calc(100vh - 60px);
    background-color: #34444c;
    position: sticky;
    top: 60px;
    border-right: 1px solid transparent;
    bottom: 0;
    left: 0;
    margin-top: 0;
    transition: all 0.2s ease-in-out 0s;
    width: 230px;
    z-index: 1001;
}

.sidebarWrapper{
    padding: 20px;
    color: #555;
}

.sidebarMenu{
    margin-bottom: 10px;
}

.link{
    text-decoration: none;
}

.sidebarTitle{
    padding: 5px 1rem;
    font-size: 1.2vw;
    color: #ebecf1;
    cursor: pointer;
    border-radius: 10px;
    align-items: center;
    display: flex;
}

.sidebarList{
    list-style: none;
    padding: 0px 5px 0px 15px;
    margin: 0;
}

.sidebarListItem{
    padding: 0.8125rem 1rem 0.8125rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: #ebecf1;
    font-size: 1vw;
}

.sidebarListItem.active, .sidebarListItem:hover{
    background-color: rgb(179, 179, 214);
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}

.sidebarTitle.active, .sidebarTitle:hover{
    background-color: rgb(180, 180, 233);
}

@media screen and (max-width:1367px) {
    .sidebar {
        flex: 1;
    }
}