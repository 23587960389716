.companyList{
    /* flex: 5; */
    flex: 0 0 100%;
    position: sticky;
    top: 60px;
    padding: 10px;
}

.companyListTitle{
    padding: 10px;
}

.companyAddButton{
    border: 1px solid #ff9b44;
    padding: 5px;
    background-color: #ff9b44;
    border-radius: 50px;
    cursor: pointer;
    color: white;
    font-size: 16px;
    display: block;
    margin: 10px 10px 10px auto;
    font-weight: 500;
    min-width: 140px;
}

/* .companyList{
    display: flex;
    align-items: center;
} */

.companyListImg{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.companyListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #086cae;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

/* .companyListDelete{
    color: red;
    cursor: pointer;
} */

.companyListDelete{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #df001e;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.companyListInfo{
    color: #3bb077;
    margin-right: 20px;
}

.MuiButton-startIcon{
    margin-right: 5px !important;
    margin-bottom: 4px !important;
}