.add-ai_model{
  width: 65vh;
  border-style: groove;
}

.ai_model-add-item{
  padding: 15px;
  display: flex;
}

.ai_model-add-item>label{
  margin-right: 5px;
  font-size: 15px;
  padding: 1px 0px;
  color: #1f1f1f;
  float: left;
  width: 50%;
}

.ai_model-add-input{
  float: right;
  width: 80%;
  height: 30px;
  box-shadow: none;
  font-size: 15px;
}

.add-ai_model-popup-main{
  position: fixed;
  top: 10%;
  left: 20%;
  width: 55%;
  /* height: 60%; */
  /* background-color:   #1f1f1f; */
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* overflow: scroll; */
  padding: 3%;
}

.add-ai_model-popup-inner{
    position: relative;
    padding: 10px;
    width: 100%;
    max-width: -moz-fit-content;
    max-width: fit-content;
    background-color: rgb(244, 243, 243);
}

.ai_model-add-item-input-file-data::-webkit-file-upload-button {
  visibility: hidden;
}


.ai_model-add-item-input-file-data::before {
  content: 'ファイルを選ぶ';
  display: inline-block;
  background-color: #F9F9F7;
  border: 2px solid #003C74;
  border-radius: 3px;
  padding: 5px 8px;
  cursor: pointer;
  color: black;
}

input[type='file'] {
  width: -webkit-fill-available;
}
