.topbar{
    right: 0px;
    background: linear-gradient(to right, #fafaeb 0%, #3958c0 100%);
    border-bottom: 1px solid transparent;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
    height: 60px;
    left: 0;
    position: sticky;
    top: 0;
    z-index: 1002;
}

.topbarWrapper{
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topLeft{
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
}

.logoIcon{
    display: inline-block;
    height: 40px;
    cursor: pointer;
}

.page-title-box{
    border-radius: 0;
    float: left;
    margin-bottom: 0;
    padding: 17px 30px;
}
.page-title-box h3{
    color: #fff;
    font-size: 20px;
    font-weight: normal;
    margin: 0;
}

.topRight{
    display: flex;
    align-items: center;
    list-style: none;
}

.top-nav-search{
    margin-right: 10px;
}

.top-nav-search form {
    margin-top: 2px;
    position: relative;
    width: 230px;
}

.form-control{
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 50px;
    color: #fff;
    height: 20px;
    padding: 10px 50px 10px 15px;
    outline-width: 0;
}

.form-control::placeholder{
    color: #fff;
}

.btn{
    background-color: transparent;
    border-color: transparent;
    color: rgba(255, 255, 255, 0.7);
    min-height: 40px;
    padding: 7px 15px;
    position: absolute;
    right: 10px;
    top: 0;
    cursor: pointer;
}

.topbarIconContainer{
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #fff;
}

.dropdown-toggle nav-link{
    color: inherit;
}

.topIconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: #7460ee;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    font-size: 10px;
}

.user-current{
    color: #fff;
    font-size: 15px;
    line-height: 60px;
    padding: 0 15px;
    height: 60px;
    margin-right: 120px;
}

.user-img{
    display: inline-block;
    position: relative; 
    margin-top: 10px;
}

.topAvatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.user-img .status{
    bottom: 20px;
    border: 2px solid #fff;
    height: 10px;
    margin: 0;
    position: absolute;
    right: 0;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}

.status.online {
    background-color: #55ce63;
}

.user-current .username{
    position: absolute;
    padding: 2px 5px 5px 10px;
    max-width: fit-content;
}


.popup-main{
    position: fixed;
    top: 60px;
    /* left: 100px; */
    /* right: 0px; */
    /* width: 20%; */
    /* height: 20vh; */
    background-color: rgb(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* overflow: scroll; */
}

.popup-inner{
    position: relative;
    padding: 10px;
    width: 100%;
    max-width: fit-content;
    background-color: #f4f4f4;
    border-style: ridge;
    /* overflow: scroll; */
}


.popup-button {
    border: 0;
    text-align: center;
    display: inline-block;
    padding: 14px;
    width: 100px;
    margin: 7px;
    color: #ffffff;
    background-color: #1c6798;
    border-radius: 8px;
    font-family: "proxima-nova-soft", sans-serif;
    font-weight: 600;
    text-decoration: none;
    transition: box-shadow 200ms ease-out;
    cursor:pointer;
  }