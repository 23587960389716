.featured{
    width: 100%;
    /*display: flex;*/
    justify-content: space-between;
}

.featuredItem{
    height: 100px;
    flex: 2;
    margin: 0px 20px;
    padding: 20px 30px 40px 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 11px -1px #000000; 
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
    border: 1px solid #ededed;
}

.featuredMoneyContainer{
    margin: 10px 0px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.featuredMoney{
    font-weight: 600;
    background-color: rgba(255, 155, 68, 0.2);
    border-radius: 100%;
    color: #ff9b44;
    display: inline-block;
    float: left;
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    margin-right: 10px;
    text-align: center;
    width: 60px;
}

.featuredMoneyRate{
    align-items: center;
    margin-left: 20px;
    text-align: right;
}

.featuredMoneyRate > h3{
    font-size: 30px;
    font-weight: 600;
}

.featuredMoneyRate > span{
    font-size: 16px;
}

.featuredIcon{
    font-size: 14px;
    margin-left: 5px;
    color: green;
}

.featuredIcon.negative{
    color: red;
}

.featuredSub{
    color: gray;
}