.dashboard{
    flex: 5;
    position: sticky;
    top: 60px;
    margin-top: 10px;
}

.pageTitle{
    color: #1f1f1f;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 5px;
    padding: 30px;
}

@media only screen and (max-width:1366px) {
}