.addCompany{
  width: 65vh;
  /* border-style: groove; */
}

.companyAddItem{
  padding: 15px;
}

.companyAddItem>label{
  margin-right: 5px;
  font-size: 15px;
  padding: 1px 0px;
  color: #1f1f1f;
}

.companyAddInput{
  float: right;
  width: 80%;
  height: 30px;
  box-shadow: none;
  font-size: 15px;
}

.add-company-popup-main{
  position: fixed;
  top: 20%;
  left: 20%;
  /* width: 60%;
  height: 60%; */
  /* background-color:   #1f1f1f; */
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* overflow: scroll; */
  padding: 5% 5% 5% 5%;
}



.delete-company-popup-main{
  position: fixed;
  top: 30%;
  left: 30%;
  /* width: 40%;
  height: 40%; */
  /* backdrop-filter: blur(5px); */
  background-color: #f4f4f4;
  border-style: groove;
  /* display: flex; */
  justify-content: center;
  align-items: baseline;
  /* overflow: scroll; */
  padding: 4% 4% 4% 4%;
}




.delete-title{
  font-size: 20px;
  padding: 5px 10px;
}

.deleteYes{
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #df001e;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.deleteNo{
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #0040ff;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

