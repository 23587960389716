.ai_model_version-popup-main{
    position: fixed;
    top: 10%;
    left: 20%;
    width: 55%;
    /* height: 60%; */
    /* background-color:   #1f1f1f; */
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* overflow: scroll; */
    padding: 3%;
  }
  
.ai_model_version-popup-inner{
      position: relative;
      padding: 10px;
      width: 100%;
      max-width: -moz-fit-content;
      max-width: fit-content;
      background-color: rgb(244, 243, 243);
}

.ai_model_version-detail-popup-main{
    position: fixed;
    top: 10%;
    left: 20%;
    width: 70%;
    /* height: 60%; */
    /* background-color:   #1f1f1f; */
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* overflow: scroll; */
    /* padding: 5% 5% 5% 5%; */
  }

.model{
    flex: 0 0 100%;
    position: sticky;
    margin-top: 30px;
    margin-left: 50px;
}

.model-item{
    display: flex;
    padding: 5px;
}

.model-item>label{
    float: left;
    width: 8%;
}

.model-item-input{
    /* float: right; */
    display: flex;
    border: none;
    background-color: inherit;
    font-size: 16px;
    /* width: 270px; */
    color: black;
}

.model-item-button{
    height: 25px;
    border-color: #003C74;
    border-radius: 7%;
    margin-right: 30px;
    cursor: pointer;
}